import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Slider.module.css';

import { FullPageWidthImageBlock } from '../../components/FullPageWidthImageBlock';
import FaderParent from '../../components/ImageGallery/FaderParent/FaderParent';
import { ButtonLink } from '../../components/ButtonLink';
import { BeratungsButtonsContainer } from '../../layouts/Header/BeratungsButtons/BeratungsButtonsContainer';

Slider.propTypes = {
  data: PropTypes.object.isRequired,
};
Slider.defaultProps = {};

export function Slider(props) {
  const { slides, teasers } = props.data;
  return (
    <section style={{ position: 'relative' }}>
      <FaderParent
        showPosSelector={false}
        showButtons={true}
        waitFor={10000}
        className="HomepageSlider"
        slides={slides.map((elem, index) => {
          const { link, line1, line2, line3, textColor } = elem;
          if (!elem.image) {
            return null;
          }
          const image = elem.image.childImageSharp.gatsbyImageData.images.fallback.src;
          const textColorMod = textColor && textColor === 'Dunkel' ? ' DarkTextBannerMod' : '';
          return (
            <FullPageWidthImageBlock
              key={`Slide_${index}`}
              hideBackgroundEffect={true}
              line1={line1}
              line2={line2}
              line3={line3}
              img={image}
              childPos={4}
              className="SliderFullWidthImageMod"
              innerClassMod={`defaultWidth${textColorMod}`}
            >
              {link.url && (
                <ButtonLink href={link.url} className={styles.Button}>
                  {link.label}
                </ButtonLink>
              )}
            </FullPageWidthImageBlock>
          );
        })}
      />
      <BeratungsButtonsContainer data={teasers} />
    </section>
  );
}
