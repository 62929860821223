import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Boxes.module.css';
import { FourColumnBlock } from '../ColumnBlocks/FourColumnBlock';
import { ReadMoreBox } from '../../components/ReadMoreBox';

Boxes.propTypes = {};
Boxes.defaultProps = {};

export function Boxes({ data }) {
  return (
    <FourColumnBlock className={styles.Boxes} columnGap={25}>
      {data.map(({ title, link, image }) => {
        const img = !image ? '' : image.childImageSharp.gatsbyImageData.images.fallback.src;
        return <ReadMoreBox key={title} title={title} href={link.url} readMoreText={link.label} img={img} />;
      })}
    </FourColumnBlock>
  );
}
