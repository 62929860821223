import React from 'react';
import PropTypes from 'prop-types';
import { BeratungsButton } from './BeratungsButton';
import { ThreeColumnBlock } from 'Blocks/ColumnBlocks/ThreeColumnBlock';

import * as styles from './BeratungsButtonsContainer.module.css';

BeratungsButtonsContainer.propTypes = {
  data: PropTypes.array,
};
BeratungsButtonsContainer.defaultProps = {};

export function BeratungsButtonsContainer(props) {
  const array = props.data;
  return (
    <div className={styles.BeratungsButtonsContainer}>
      <ThreeColumnBlock columnGap={20} className="defaultWidth">
        {array.map((element, index) => {
          return (
            <BeratungsButton
              key={`BeratungsButton_` + index}
              href={element.link.url}
              linkLabel={element.link.label}
              icon={element.icon.publicURL}
              alt={element.title}
              text={element.title}
            />
          );
        })}
      </ThreeColumnBlock>
    </div>
  );
}
