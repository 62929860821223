import React from 'react';
import PropTypes from 'prop-types';

import PosSelector from './PosSelector';

import * as styles from './FaderParent.module.css';

//elemWrappers can be refactored

export default class FaderParent extends React.Component {
  static propTypes = {
    className: PropTypes.string, //override some css
    waitFor: PropTypes.number,
    leftButton: PropTypes.object,
    rightButton: PropTypes.object,
    showButtons: PropTypes.bool,
    showPosSelector: PropTypes.bool,
    slides: PropTypes.array.isRequired,
  };
  static defaultProps = {
    showPosSelector: false,
    showButtons: false,
    waitFor: 0,
  };
  timerTime = 0;
  tickTime = 200;
  blockTimerSlide = false;
  constructor(props) {
    super(props);
    this.props = props;
    //make array if needed
    let images = props.slides;

    //swipe events
    this.xDown = null;
    this.yDown = null;
    //refs
    this.slider = null;
    this.imagesContainer = null;
    this.arrayOfElemsRef = [];
    //state
    this.state = {
      randomId: Math.round(Math.random() * 999999),
      activePos: 0,
      maxTimesSlide: 0,
      imagesLeft: 0,
      imagesArray: images,
    };
  }
  componentDidMount() {
    if (this.props.waitFor) {
      const waitFor = this.props.waitFor;
      this.intervalId = window.setInterval(() => {
        this.tick();
      }, this.tickTime);
    }
    setTimeout(() => {
      this.setMaxSlide();
    });
    window.addEventListener('resize', this.setMaxSlide);
  }
  componentWillUnmount() {
    if (this.props.waitFor) {
      window.clearInterval(this.intervalId);
    }
    window.removeEventListener('resize', this.setMaxSlide);
  }
  tick = () => {
    if (!this.blockTimerSlide) {
      this.timerTime += this.tickTime;
    }
    if (this.timerTime >= this.props.waitFor) {
      this.timerTime = 0;
      this.goRight();
    }
  };
  getMaxSlide = () => {
    // console.log(this.arrayOfElemsRef.length)
    //get maxValues
    const nrTilesInWidth = Math.round(this.imagesContainer.offsetWidth / this.arrayOfElemsRef[0].offsetWidth);
    // console.log(this.imagesContainer.offsetWidth +'/'+this.arrayOfElemsRef[0].offsetWidth+ '=' +nrTilesInWidth)
    let maxSlides = Math.max(Math.ceil(this.arrayOfElemsRef.length / nrTilesInWidth) - 1, 0);
    // console.log(maxSlides)
    return maxSlides;
  };
  setMaxSlide = () => {
    const maxSlides = this.getMaxSlide();
    if (maxSlides != this.state.maxTimesSlide) {
      this.setState({ maxTimesSlide: maxSlides });
      this.handlePosSelect(0, false);
    }
  };
  goLeft = () => {
    this.timerTime = 0;
    this.setMaxSlide();
    let val = Math.max(this.state.activePos - 1, 0);
    this.handlePosSelect(val, true);
  };
  goRight = () => {
    this.timerTime = 0;
    this.setMaxSlide();
    let val = this.state.activePos + 1;
    if (this.intervalId) {
      val = val > this.state.maxTimesSlide ? 0 : val;
    } else {
      val = Math.min(val, this.state.maxTimesSlide);
    }
    this.handlePosSelect(val, true);
  };
  handlePosSelect = (val, calc = true) => {
    this.timerTime = 0;
    if (calc) {
      this.setMaxSlide();
    }
    if (this.state.maxTimesSlide >= val) {
      this.setState({ activePos: val, imagesLeft: val * -100 });
    }
  };
  handleTouchStart = (evt) => {
    this.xDown = evt.touches[0].clientX;
    this.yDown = evt.touches[0].clientY;
  };
  handleTouchMove = (evt) => {
    if (!this.xDown || !this.yDown) {
      return;
    }
    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.xDown - xUp;
    var yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        this.goRight();
      } else {
        this.goLeft();
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
  };

  render() {
    const inlineStyle = this.props.style ? this.props.style : {};
    const containerClasses = this.props.className ? ' ' + this.props.className : '';
    return (
      <div
        className={styles.FaderParent + containerClasses}
        ref={(myFriend) => {
          this.slider = myFriend;
        }}
        style={inlineStyle}
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
        // onMouseOver={()=>this.blockTimerSlide = true}
        // onMouseOut={()=>this.blockTimerSlide = false}
      >
        <div className={styles.imagesContainer} ref={(myFriend) => (this.imagesContainer = myFriend)}>
          {this.state.imagesArray.map((elem, index) => {
            let addClass = styles.hideAnim;
            if (this.state.activePos == index) {
              addClass = styles.showAnim;
            }
            return (
              <div
                key={`sliderElement_${this.state.randomId}_${index}`}
                className={`${styles.sliderElemWrapper} ${addClass}`}
                ref={(elemRef) => {
                  this.arrayOfElemsRef[index] = elemRef;
                }}
                // style={{left: `${this.state.imagesLeft}%`}}
              >
                {elem}
              </div>
            );
          })}
        </div>

        {this.props.children}
        {this.state.maxTimesSlide > 0 && this.props.showButtons && (
          <div className={styles.leftButton} onClick={this.goLeft}>
            {this.props.leftButton ? this.props.leftButton : getLeftArrow()}
          </div>
        )}
        {this.state.maxTimesSlide > 0 && this.props.showButtons && (
          <div className={styles.rightButton} onClick={this.goRight}>
            {this.props.rightButton ? this.props.rightButton : getLeftArrow()}
          </div>
        )}
        {this.state.maxTimesSlide > 0 && this.props.showPosSelector && (
          <PosSelector onClick={this.handlePosSelect} activePos={this.state.activePos} amount={this.state.maxTimesSlide} />
        )}
      </div>
    );
  }
}

function getLeftArrow() {
  return (
    <svg viewBox="0 0 17 32">
      <path d="M14.897 31.669c0.221 0.221 0.497 0.331 0.8 0.331s0.579-0.11 0.8-0.331c0.441-0.441 0.441-1.159 0-1.6l-14.069-14.069 14.069-14.069c0.441-0.441 0.441-1.159 0-1.6s-1.159-0.441-1.6 0l-14.897 14.869c-0.441 0.441-0.441 1.159 0 1.6l14.897 14.869z"></path>
    </svg>
  );
}
