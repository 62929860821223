import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './BeratungsButton.module.css';
import { LinkWrapper } from '@mangoart/gatsby-ui/components/LinkWrapper';

BeratungsButton.propTypes = {
  href: PropTypes.string.isRequired,
  linkLabel: PropTypes.string,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
BeratungsButton.defaultProps = {
  linkLabel: 'weiterlesen',
};

export function BeratungsButton(props) {
  return (
    <LinkWrapper href={props.href} className={styles.BeratungsButton} title={props.text}>
      <div className={styles.flexContainer}>
        <img className={styles.icon} src={props.icon} alt={props.alt} />
        <div className={styles.text}>
          <ReplaceBreaks text={props.text} />
          <span className={styles.link}>
            <span>></span>
            <span>{props.linkLabel}</span>
          </span>
        </div>
      </div>
    </LinkWrapper>
  );
}

function ReplaceBreaks({ text }) {
  let newText = text.replace(/<br.*?>/g, '<br/>');
  newText = newText.split('<br/>');
  return (
    <span>
      {newText.map((e, i) => (
        <span key={`newText_${newText.join()}_${i}`}>
          {e}
          {newText.length > 1 && <br />}
        </span>
      ))}
    </span>
  );
}
