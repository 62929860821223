import React from 'react';
import PropTypes from 'prop-types';
import { LinkWrapper } from '@mangoart/gatsby-ui/components/LinkWrapper';
import { ButtonLink } from 'Components/ButtonLink';

import * as styles from './ReadMoreBox.module.css';

export function ReadMoreBox(props) {
  const imgURL = props.img ? `url('${props.img}')` : '';

  return (
    <div className={styles.ReadMoreBox}>
      <div className={styles.innerBorder}>
        <div className={styles.imageContainer}>
          {props.href && (
            <LinkWrapper href={props.href}>
              <div className={styles.image} style={{ backgroundImage: imgURL }} />
            </LinkWrapper>
          )}
          {!props.href && <div className={styles.image} style={{ backgroundImage: imgURL }} />}
        </div>

        <div className={styles.textContainer}>
          <p>{filterBreaks(props.title)}</p>
          {props.href && (
            <ButtonLink href={props.href} className={styles.readMore} title={props.title}>
              >&nbsp;{props.readMoreText}
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
}

ReadMoreBox.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  readMoreText: PropTypes.string,
};
ReadMoreBox.defaultProps = {
  readMoreText: '> weiterlesen',
};
function filterBreaks(text) {
  return text.replace(/-<br.*?>/g, '').replace(/<br.*?>/g, '');
}
