import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import ContentBlock from '../blocks/ContentBlock';
import { PortraitImage } from '../blocks/PortraitImage';
import { CallToAction } from '../blocks/CallToAction';
import { Banner } from '../blocks/Banner';
import { Boxes } from '../blocks/Boxes';
import { DefaultLayout } from '../layouts/Default';
import { Slider } from '../blocks/Slider';

function IndexPage({ data, location }) {
  const { pageData, contactData, site } = data;
  const { siteMetadata } = site;
  const { content1, content2, content3, banner, metadata, opengraph, boxes, header_banner, header_teaser } = pageData;

  return (
    <DefaultLayout>
      <Slider data={{ slides: header_banner, teasers: header_teaser }} />
      <ContentBlock data={content1} />
      <Boxes data={boxes} />
      <ContentBlock data={content2} />
      <Banner data={banner} />
      <ContentBlock data={content3} />
      <PortraitImage />
      <CallToAction data={contactData} />
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
}

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    contactData: cockpitRegionKontaktInformationen {
      id
      telephone
      email
    }
    pageData: cockpitRegionStartseite {
      header_banner {
        line1
        line2
        line3
        textColor
        link {
          url
          label
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      header_teaser {
        id
        title
        icon {
          publicURL
        }
        link {
          url
          label
          type
        }
      }
      boxes {
        id
        title
        link {
          url
          label
          type
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content1 {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content2 {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content3 {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      banner {
        id
        name
        line1
        line2
        line3
        link {
          url
          label
          type
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
