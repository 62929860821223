import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './FourColumnBlock.module.css';

FourColumnBlock.propTypes = {
  columnGap: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
};

FourColumnBlock.defaultProps = {
  columnGap: 50,
  className: '',
  style: {},
};

export function FourColumnBlock(props) {
  const paddingX = props.columnGap ? Math.round(props.columnGap / 2) : 0;

  if (!props.children) {
    return null;
  }
  const addClassName = props.className ? ` ${props.className}` : '';
  return (
    <div className={styles.FourColumnBlock + addClassName} style={props.style} role="complementary">
      <div className={styles.innerMaxWidthBlock}>
        <div
          className={styles.marginsWrapper}
          style={{ width: `calc(100% + ${props.columnGap}px)`, marginLeft: `-${paddingX}px`, marginRight: `-${paddingX}px` }}
        >
          {props.children.map((elem, index) => (
            <div style={{ padding: `0 ${paddingX}px` }} key={index}>
              {elem}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
