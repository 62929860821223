import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './PosSelector.module.css';

//sends a selected index back to a function

class PosSelector extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    activePos: PropTypes.number.isRequired,
  };
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    let array = [];
    for (let i = 0; i <= this.props.amount; i++) {
      array[i] = (
        <div key={'posSelect_' + i} className={styles.pos} onClick={() => this.props.onClick(i)}>
          {i === this.props.activePos ? <div className={styles.selected}></div> : null}
        </div>
      );
    }
    return <div className={styles.PosSelector}>{array}</div>;
  }
}

export default PosSelector;
